<template>
  <header>
    <nav class="navbar navbar-expand-xl navbar-main navbar-light bg-light">
      <div class="container-fluid">
        <a class="navbar-brand" href="/">
          <img :src="logo" width="35" alt="America Shipping" class="mb-1" />
          <span class="text-uppercase fw-bold"> America Shipping</span>
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarScroll"
          aria-controls="navbarScroll"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarScroll">
          <ul class="navbar-nav col-lg-9 justify-content-lg-center">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{ $localize("Vehicles", $store.getters.getLang) }}
              </a>
              <ul class="dropdown-menu">
                <li v-for="car in cars" :key="car.url">
                  <router-link :to="car.url" class="dropdown-item">
                    <i v-if="car.icon" :class="'icon ' + car.icon"></i>
                    {{ $localize(car.title, $store.getters.getLang) }}
                  </router-link>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{ $localize("My Office", $store.getters.getLang) }}
              </a>
              <ul class="dropdown-menu">
                <li v-for="office in offices" :key="office.url">
                  <router-link :to="office.url" class="dropdown-item">
                    <i v-if="office.icon" :class="'icon ' + office.icon"></i>
                    {{ $localize(office.title, $store.getters.getLang) }}
                  </router-link>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{
                  $localize("Inland delivery & freight", $store.getters.getLang)
                }}
              </a>
              <ul class="dropdown-menu">
                <li v-for="other in others" :key="other.url">
                  <router-link :to="other.url" class="dropdown-item">
                    <i v-if="other.icon" :class="'icon ' + other.icon"></i>
                    {{ $localize(other.title, $store.getters.getLang) }}
                  </router-link>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <router-link to="/news" class="nav-link">
                <i class="bi bi-newspaper"></i>
                {{ $localize("News", $store.getters.getLang) }}
              </router-link>
            </li>
          </ul>
          <ul class="navbar-nav col-lg-3 content-end">
            <li class="nav-item">
              <div class="dropdown content-end lang-menu">
                <a
                  href="#"
                  class="text-decoration-none dropdown-toggle text-dark"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    :src="getLangImage(lang)"
                    :alt="lang"
                    width="32"
                    height="30"
                  />&nbsp;
                  <span class="pt-5 user-top text-uppercase">{{ lang }}</span>
                </a>
                <ul class="dropdown-menu text-small">
                  <SelectLang />
                </ul>
              </div>
            </li>
            <li class="nav-item">
              <div class="dropdown content-end user-menu">
                <a
                  href="#"
                  class="text-decoration-none dropdown-toggle text-dark"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    :src="avatarSrc"
                    alt="avatar"
                    width="32"
                    height="32"
                    class="rounded-circle"
                  />
                  <span class="user-top name-u">{{ userName }}</span>
                </a>
                <ul class="dropdown-menu text-small">
                  <li>
                    <router-link :to="'/settings'" class="dropdown-item">
                      <i class="icon bi bi-sliders mr-4"></i>
                      {{ $localize("Settings", $store.getters.getLang) }}
                    </router-link>
                  </li>
                  <!--<li>
                    <router-link :to="'/profile'" class="dropdown-item">
                      <i class="icon bi bi-person-circle mr-4"></i> Profile
                    </router-link>
                  </li>-->
                  <li>
                    <a
                      href="#"
                      v-if="this.theme === 'dark'"
                      class="dropdown-item dtheme"
                      @click.prevent="toggleTheme"
                    >
                      <i class="icon bi bi-brightness-high-fill"></i>
                      {{ $localize("Light Mode", $store.getters.getLang) }}
                    </a>
                    <a
                      href="#"
                      v-else
                      class="dropdown-item ltheme"
                      @click.prevent="toggleTheme"
                    >
                      <i class="icon bi bi-moon-stars-fill"></i>
                      {{ $localize("Dark Mode", $store.getters.getLang) }}
                    </a>
                  </li>
                  <li><hr class="dropdown-divider" /></li>
                  <li>
                    <a href="#" class="dropdown-item" @click.prevent="logout">
                      <i class="icon bi bi-box-arrow-right mr-4"></i>
                      {{ $localize("Sign out", $store.getters.getLang) }}
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>
<script>
import axios from "axios";
import SelectLang from "@/components/app/SelectLang.vue";
export default {
  data: () => ({
    userName: localStorage.getItem("userName") || "No name",
    avatarSrc: localStorage.getItem(`userAvatar`) || "/img/avatar.webp",
    theme: "light",
    logo: "/img/ml.png",
    cars: [
      { title: "Vehicle list", url: "/cars", icon: "bi bi-car-front-fill" },
      { title: "Statistics", url: "/statistic", icon: "bi bi-clipboard-data" },
      { title: "Archive", url: "/archive", icon: "bi bi-file-zip-fill" },
    ],
    offices: [
      {
        title: "Balance",
        url: "/balances",
        icon: "bi bi-credit-card-2-front",
      },
      {
        title: "Calculator",
        url: "/calculator",
        icon: "bi bi-calculator-fill",
      },
      { title: "Carfax", url: "/carfax", icon: "bi bi-file-earmark-pdf" },
      //{ title: "AutoStat", url: "/autostat", icon: "bi bi-bookmarks" },
      {
        title: "Titles",
        url: "/titles",
        icon: "bi bi-file-earmark-excel-fill",
      },
      {
        title: "Invoices",
        url: "/invoices",
        icon: "bi bi-receipt",
      },
      { title: "Customers", url: "/customers", icon: "bi bi-people-fill" },
    ],
    others: [
      { title: "Copart", url: "/copart", icon: false },
      { title: "Iaai", url: "/iaai", icon: false },
      { title: "Freight", url: "/frieghts", icon: false },
    ],
  }),

  components: {
    SelectLang,
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
  },
  mounted() {
    this.theme = localStorage.getItem("theme");
    if (this.theme === "light") {
      this.logo = "/img/ml.png";
    } else {
      this.logo = "/img/dml.png";
    }
  },
  methods: {
    toggleTheme() {
      if (this.theme === "dark") {
        document.body.classList.remove("dark-theme");
        localStorage.setItem("theme", "light");
        this.theme = "light";
        this.logo = "/img/ml.png";
      } else {
        this.theme = "dark";
        this.logo = "/img/dml.png";
        document.body.classList.toggle("dark-theme");
        localStorage.setItem("theme", "dark");
      }
    },
    logout() {
      // Очистить токен доступа из localStorage
      localStorage.removeItem("accessToken");

      // Удалить заголовок Authorization из будущих запросов
      delete axios.defaults.headers.common["Authorization"];

      // Установить флаг аутентификации в хранилище в значение false
      this.$store.commit("setAuthentication", false);

      // Перенаправить пользователя на страницу входа
      this.$router.replace("/login");
    },
    getLangImage(lang) {
      try {
        // Используем require для динамической загрузки изображения
        return `/img/${lang}.webp`;
      } catch (error) {
        console.error("Image not found:", error);
        return ""; // Возвращаем пустую строку, если изображение не найдено
      }
    },
  },
};
</script>
<style scoped>
.name-u {
  display: inline-block;
}
.navbar-toggler {
  margin-right: 20px;
}
.navbar-brand {
  font-size: 15px !important;
}
.navbar-toggler:focus {
  box-shadow: 0 0 0 0 !important;
}
@media (max-width: 1399px) and (min-width: 1200px) {
  .name-u {
    display: none;
  }
}
</style>
