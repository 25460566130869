<template>
  <div>
    <NavMenu />
    <div class="container-xxl ml-5 mt-3 mb-5 pb-4">
      <router-view />
    </div>
    <!--<button class="btn btn-dark btn-lg fixed-button">
      <i class="icon-chat bi bi-chat-text"></i>
    </button>-->
    <ScrollToTopButton />
    <footer class="footer mt-auto py-3 bg-light fixed-bottom">
      <div class="container">
        <span class="text-muted">America Shipping 2.0</span>
      </div>
    </footer>
  </div>
</template>

<script>
import NavMenu from "/src/components/app/NavMenu.vue";
import ScrollToTopButton from "@/components/app/ScrollToTopButton.vue";
export default {
  name: "main-layout",
  components: {
    NavMenu,
    ScrollToTopButton,
  },
  mounted() {
    // Получаем сохраненную тему из localStorage
    const savedTheme = localStorage.getItem("theme");

    // Применяем класс `dark-theme` к `body` в зависимости от сохраненной темы
    const isDarkMode = savedTheme === "dark";
    document.body.classList.toggle("dark-theme", isDarkMode);
  },
  watch: {
    $route(to) {
      document.title = to.meta.title || "America Shipping";
    },
  },
};
</script>
